import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';

interface IContentProps {
  color?: string | null;
  colorBackground?: string | null;
  fontSize?: string | null;
}

export const useStyles = makeStyles<Theme, IContentProps>((theme) =>
  createStyles({
    container: {
      backgroundColor: ({ colorBackground }) => colorBackground || ColorsPalette.primary,
      color: ({ color }) => color || ColorsPalette.neutral2,
      fontSize: ({ fontSize }) => fontSize || 14,
    },
  }),
);
