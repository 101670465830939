import { mobileMode } from '@constants/config';
import { IApiErrorResponse } from '@interfaces/fetchService';

export const isHaveErrors = (errors: IApiErrorResponse[], errorKeys: number[]):boolean => {
  if(errors && errors.length > 0 && errorKeys && errorKeys.length > 0){
    return errors.some((errorItem)=> errorKeys.includes(errorItem.errorCode) );
  }
  return false;
};

export const isTrackingDisabledByAppSettings = (trackingDisabled: boolean | null | undefined): boolean => {
  return mobileMode && [null, true, undefined].includes(trackingDisabled);
};
