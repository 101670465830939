import { mapSortToApiRequest } from '@components/pages/productsList/utils';
import * as actions from '@constants/actions/wishList';
import { itemsPerPageWishList } from '@constants/index';
import { ESortType } from '@constants/sort';
import { RequestMethods } from '@constants/types';
import {
  wishListCountUrl,
  wishListGetIDsUrl,
  wishListRemoveItemUrl,
  wishListUpdateItemUrl,
  wishListUrl,
} from '@constants/urls';
import { ICartItem } from '@interfaces/cart';
import { ESessionStatuses, ICallApiAction } from '@interfaces/fetchService';
import { EProductsListSort } from '@interfaces/productsList';
import { IStore } from '@interfaces/store';
import { IWishListSortChange } from '@interfaces/wishList/actions';
import { CALL_AD, ICallADPayload } from '../../services/AnalyticEvent';
import { EAnalyticEventName } from '../../services/AnalyticEvent/types';
import { CALL_API } from '../../services/SSRService';
import { addParamsToUrl } from '../../services/SSRService/utils';

export interface IWishListGetParams {
  country: string;
  customerAuth?: string;
  lang: string;
  page?: number;
  size?: number;
  [EProductsListSort.sort]: string;
}

type TWishListGet = (params: IWishListGetParams) => ICallApiAction;
export const acWishListGet: TWishListGet = (params) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(wishListUrl(), { ...params }),
    method: RequestMethods.GET_METHOD,
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: params?.page && params?.page > 0 ? [actions.WISHLIST_ITEMS_GET_NEXT_REQUEST, actions.WISHLIST_ITEMS_GET_NEXT_SUCCESS, actions.WISHLIST_ITEMS_GET_NEXT_FAILED] : [actions.WISHLIST_ITEMS_GET_REQUEST, actions.WISHLIST_ITEMS_GET_SUCCESS, actions.WISHLIST_ITEMS_GET_FAILED],
  },
});

export const acWishListGetAction = (page = 0) => (dispatch, getState: () => IStore) => {
  const {
    auth: { token },
    pageData: { countryCode, languageCode },
    wishList: { sortDirection },
  } = getState();
  const sortParams = mapSortToApiRequest(sortDirection);
  const locale = { country: countryCode || '', lang: languageCode || '' };
  const authParams = token?.access_token ? { customerAuth: token.access_token } : {};
  return dispatch(
    acWishListGet({
      ...locale,
      ...sortParams,
      ...authParams,
      page,
      size: itemsPerPageWishList,
    }));
};

export interface IWishListAddParams {
  country: string;
  customerAuth?: string;
  lang: string;
  request: {
    productId: number;
  };
}


type TWishListAdd = (params: IWishListAddParams, adPayload?: ICallADPayload) => any;
export const acWishListAdd: TWishListAdd = ({ country, customerAuth, lang, request }, adPayload) => ({
  [CALL_AD]: {
    event: EAnalyticEventName.add_to_wishlist,
    payload: adPayload,
  },
  [CALL_API]: {
    body: { ...request },
    endpoint: addParamsToUrl(wishListUrl(), { country, customerAuth, lang }),
    facebook: true,
    method: RequestMethods.POST_METHOD,
    sessionId: {
      status: ESessionStatuses.createSessionBefore,
    },
    types: [actions.WISHLIST_ITEMS_ADD_REQUEST, actions.WISHLIST_ITEMS_ADD_SUCCESS, actions.WISHLIST_ITEMS_ADD_FAILED],
  },
});

export const acWishListAddAction = (request, adPayload?: ICallADPayload) => (dispatch, getState: () => IStore) => {
  const { auth: { token }, pageData: { countryCode, languageCode } } = getState();
  if (languageCode && countryCode && request)
    return dispatch(acWishListAdd({
      country: countryCode,
      customerAuth: token?.access_token,
      lang: languageCode,
      request,
    }, adPayload));
};

export interface IWishListRemoveParams {
  country: string;
  customerAuth?: string;
  lang: string;
  productId: number;
}

type TWishListRemove = (params: IWishListRemoveParams) => any;
export const acWishListRemove: TWishListRemove = ({ country, customerAuth, lang, productId }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(wishListRemoveItemUrl(productId), { country, customerAuth, lang }),
    method: RequestMethods.DELETE_METHOD,
    requestParams: { productId },
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [
      actions.WISHLIST_ITEMS_REMOVE_REQUEST,
      actions.WISHLIST_ITEMS_REMOVE_SUCCESS,
      actions.WISHLIST_ITEMS_REMOVE_FAILED,
    ],
  },
});

export const acWishListRemoveAction = (productId) => (dispatch, getState: () => IStore) => {
  const { auth: { token }, pageData: { countryCode, languageCode } } = getState();
  if (languageCode && countryCode && productId)
    return dispatch(acWishListRemove({
      country: countryCode,
      customerAuth: token?.access_token,
      lang: languageCode,
      productId,
    }));
};

export const acWishListInit = () => async (dispatch, getState: () => IStore) => {
  const {
    wishList: { loaded },
  } = getState();
  if (!loaded) {
    await dispatch(acWishListGetAction());
  }
  await dispatch(acWishListGetIdAction());
};

export interface IWishListUpdateParams {
  country: string;
  customerAuth?: string;
  lang: string;
  productId: number;
  newProductId: number;
  newSizeId?: number | string;
}

type TWishListUpdate = (params: IWishListUpdateParams) => any;
export const acWishListUpdate: TWishListUpdate = ({
  country,
  customerAuth,
  lang,
  productId,
  newProductId,
}) => ({
  [CALL_API]: {
    body: { newProductId },
    endpoint: addParamsToUrl(wishListUpdateItemUrl(productId), { country, customerAuth, lang }),
    method: RequestMethods.PUT_METHOD,
    requestParams: { productId, newProductId },
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [
      actions.WISHLIST_UPDATE_ITEM_REQUEST,
      actions.WISHLIST_UPDATE_ITEM_SUCCESS,
      actions.WISHLIST_UPDATE_ITEM_FAILED,
    ],
  },
});

type IWishListUpdateActionParams = {
  newProductId: number;
  productId: number
};
type TWishListUpdateAction = (params: IWishListUpdateActionParams) => any;
export const acWishListUpdateAction: TWishListUpdateAction = ({
  productId,
  newProductId,
}) => (dispatch, getState: () => IStore) => {
  const {
    auth: { token },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode && productId && newProductId) {
    return dispatch(acWishListUpdate({
      country: countryCode,
      customerAuth: token?.access_token,
      lang: languageCode,
      newProductId,
      productId,
    }));
  }
  return Promise.resolve(false);
};


type TWishListUpdateItemLocal = (params: Partial<ICartItem>) => any;
export const acWishListUpdateItemLocal: TWishListUpdateItemLocal = (params) => ({
  payload: { ...params },
  type: actions.WISHLIST_UPDATE_ITEM_LOCAL,
});


type TWishListReplace = (params: IWishListUpdateParams) => any;
export const acWishListReplace: TWishListReplace = ({
  country,
  customerAuth,
  lang,
  productId,
  newProductId,
  newSizeId,
}) => ({
  [CALL_API]: {
    body: { newProductId },
    endpoint: addParamsToUrl(wishListUpdateItemUrl(productId), { country, customerAuth, lang }),
    method: RequestMethods.PUT_METHOD,
    requestParams: { productId, newProductId, newSizeId },
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [
      actions.WISHLIST_REPLACE_ITEM_REQUEST,
      actions.WISHLIST_REPLACE_ITEM_SUCCESS,
      actions.WISHLIST_REPLACE_ITEM_FAILED,
    ],
  },
});

type TWishListReplaceAction = (params: Partial<IWishListUpdateParams>) => any;
export const acWishListReplaceAction: TWishListReplaceAction = ({
  productId,
  newProductId,
  newSizeId,
}) => (dispatch, getState: () => IStore) => {
  const {
    auth: { token },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode && productId && newProductId) {
    return dispatch(acWishListReplace({
      country: countryCode,
      customerAuth: token?.access_token,
      lang: languageCode,
      newProductId,
      newSizeId,
      productId,
    }));
  }
  return Promise.resolve(false);
};

export const acWishListChangeSorting = (sortDirection: ESortType): IWishListSortChange => ({
  payload: sortDirection,
  type: actions.WISHLIST_SORT_CHANGE,
});

export interface IWishListCountParams {
  country: string;
  customerAuth?: string;
  lang: string;
}

type TWishListCount = (params: IWishListCountParams) => ICallApiAction;
export const acWishListCount: TWishListCount = (params) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(wishListCountUrl(), { ...params }),
    method: RequestMethods.GET_METHOD,
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [actions.WISHLIST_ITEMS_COUNT_REQUEST, actions.WISHLIST_ITEMS_COUNT_SUCCESS, actions.WISHLIST_ITEMS_COUNT_FAILED],
  },
});

export const acWishListCountAction = () => (dispatch, getState: () => IStore) => {
  const { auth: { token }, pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  dispatch(
    acWishListCount({
      ...locale,
      customerAuth: token?.access_token,
    }));
};

type TWishListGetId = (params: {
  country: string;
  customerAuth?: string;
  lang: string;
}) => ICallApiAction;
export const acWishListGetId: TWishListGetId = (params) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(wishListGetIDsUrl(), { ...params }),
    method: RequestMethods.GET_METHOD,
    sessionId: { status: ESessionStatuses.dontCallWithoutSession },
    types: [actions.WISHLIST_ID_GET_REQUEST, actions.WISHLIST_ID_GET_SUCCESS, actions.WISHLIST_ID_GET_FAILED],
  },
});

export const acWishListGetIdAction = () => async (dispatch, getState: () => IStore) => {
  const { auth: { token }, pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  await dispatch(
    acWishListGetId({
      ...locale,
      customerAuth: token?.access_token,
    }));
};
